const DATA_REGEX = /\d{3,}_\w+/
const SHORT_COURSE_NAME = /^\d+(\.\d+)?$/
const SUBJECT_KEY_REGEX = [
  {
    regexp: /MATHEMATICS/,
    title: 'Maths'
  },
  {
    regexp: /LITERACY/,
    title: 'Literacy'
  }
]

export type ICourseIdPair = {
  courseId: number;
  curriculumId: number;
}

export interface ICourseMap {
  id: ICourseIdPair;
  group: string;
  shortName: string;
  name: string;
}

export interface IRegModelMap {
  [key: string]: string | ICourseMap[];
}

export interface IRegModelParam {
  paramname: string;
  value: string;
}

// convert value/key array to object
// converting some key pieces along the way

export default (regModelParams: IRegModelParam[]): IRegModelMap => regModelParams.reduce((map, value) => {
  let v: string | ICourseMap[] = value['value']
  let k = value['paramname']

  if (v && v.match(DATA_REGEX)) {
    v = v.split(':').map((value) => {
      let val = value.split('_')
      let shortName = (val[2] ? val[2] : val[1])
      let name = shortName
      if (shortName.match(SHORT_COURSE_NAME)) {
        name = `Part ${shortName}`
        for (let subjectKeyRegexp of SUBJECT_KEY_REGEX) {
          if (k.match(subjectKeyRegexp.regexp)) {
            name = `${name} ${subjectKeyRegexp.title}`
            break
          }
        }
      }
      let [courseId, curriculumId] = val[0].split('-').map(v => Number(v))
      return {
        id: { courseId, curriculumId },
        group: val[1].split('.')[0],
        shortName,
        name
      }
    })
  }
  map[k] = v
  return map
}, {} as IRegModelMap)
